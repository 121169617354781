<template>
  <ReflectIconButton
    v-if="memberDefinition"
    icon="icon-info"
    @click.stop="() => (isDefinitionModalOpen = true)"
  />
  <Teleport
    v-if="isDefinitionModalOpen && memberDefinition"
    to="#action"
  >
    <ReflectModal @cancel="() => (isDefinitionModalOpen = false)">
      <template #title>
        <AnalyticsModuleInfoTitle
          :source-type="memberDefinition.source"
          :title="member.label"
          :default-title="member.defaultLabel"
        />
      </template>
      <template #default>
        <AnalyticsModuleInfo :content="memberDefinition" />
      </template>
    </ReflectModal>
  </Teleport>
</template>

<script lang="ts">
import type { DIMENSION_TITLES, MEASURE_TITLES } from '~/constants/analytics'
import {
  DIMENSION_DEFINITION_BY_TITLE,
  MEASURE_DEFINITION_BY_TITLE
} from '~/constants/analyticsDefinitions'
import {
  MemberType,
  type DimensionOption,
  type MeasureOption
} from '~/types/analytics'

export default {
  name: 'Definition',
  props: {
    member: {
      type: Object as PropType<DimensionOption | MeasureOption>,
      required: true
    },
    memberType: {
      type: String as PropType<MemberType>,
      required: true
    }
  },
  data(): {
    isDefinitionModalOpen: boolean
  } {
    return {
      isDefinitionModalOpen: false
    }
  },
  computed: {
    memberDefinition() {
      if (this.memberType === MemberType.MEASURE) {
        return (
          MEASURE_DEFINITION_BY_TITLE[
            this.member.shortTitle as MEASURE_TITLES
          ] || null
        )
      } else {
        return (
          DIMENSION_DEFINITION_BY_TITLE[
            this.member.shortTitle as DIMENSION_TITLES
          ] || null
        )
      }
    }
  }
}
</script>
